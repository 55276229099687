












































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DialogoDeEdicaoDeCliente from '@/components/cliente/DialogoDeEdicaoDeCliente.vue'
import { Cliente } from '@/models'
import { FindClienteUseCase } from '@/usecases'
import { aplicarMascaraParaCpfOculto, formatarCnpjOuCpf } from '@/shareds/formatadores'
import { criarCliente } from '@/shareds/cliente-shareds'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'
import DialogoDeCsvClientes from './DialogoDeCsvClientes.vue'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'

@Component({
	components: {
		DataTableDeCrud,
		DialogoDeEdicaoDeCliente,
		DialogoDeCsvClientes,
	},
})
export default class TelaDeClientes extends Vue {
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeCliente
	@Ref() dialogoDeCsvCliente!: DialogoDeCsvClientes

	headers = [
		{ text: 'Nome', value: 'razaoSocialOuNome' },
		{ text: 'CPF/CNPJ', value: 'cnpjOuCpfFormatado' },
		{ text: 'Estado', value: 'endereco.uf' },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalRegistros = -1

	carregando = false

	busca = ''

	editando = true

	clientes: Cliente[] = []

	findUseCase = new FindClienteUseCase()
	cancelToken: CancelTokenSource | null = null

	created() {
		this.buscar()
	}

	async buscar() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const params = {
				page: this.paginacao.page - 1,
				busca: this.busca || undefined,
				size: this.paginacao.itemsPerPage,
				gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos ? UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id) : null,
			}

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const page = await this.findUseCase.find(params, axiosConfig)
			this.totalRegistros = page.totalElements
			this.clientes = page.content
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
			this.carregando = false
		}
	}

	get itensFormatados(): Display[] {
		return this.clientes.map(cliente => ({
			...cliente,
			cnpjOuCpfFormatado: cliente.cnpjOuCpf.length <= 11
				? aplicarMascaraParaCpfOculto(cliente.cnpjOuCpf)
				: formatarCnpjOuCpf(cliente.cnpjOuCpf),
		}))
	}

	abrirDialogoDeCriarCliente() {
		this.editando = false
		const cliente = criarCliente()
		this.dialogoDeEdicao.mostrar(cliente, true)
	}

	atualizarCliente(cliente: Cliente) {
		if (!cliente) return

		const indice = this.clientes.findIndex(
			clienteExistente => clienteExistente.id === cliente.id,
		)
		indice === -1
			? this.clientes.push(cliente)
			: this.clientes.splice(indice, 1, cliente)
		
	}

	async abrirDialogoDeEditarCliente (indice: number ) {
		this.editando = true
		const clienteId = this.clientes[indice].id
		this.cancelToken = axios.CancelToken.source()

		const params = {
			gruposEconomicosId: UserLoginStore.usuario?.gruposEconomicos ? UserLoginStore.usuario?.gruposEconomicos.map(grupoEconomico => grupoEconomico.id) : null,
		}
		const axiosConfig = {
			cancelToken: this.cancelToken.token,
		}
		
		const cliente = await this.findUseCase.get(clienteId, params, axiosConfig)
		cliente.createdAt = dateToPtBrFormat(cliente.createdAt)
		this.dialogoDeEdicao.mostrar(cliente, false)
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscar

	@Watch('busca')
	onChangeBusca() {
		this.paginacao.page = 1
		this.buscar()
	}
}

export interface Display extends Cliente {
	cnpjOuCpfFormatado: string
}
